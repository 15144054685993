/**
 * handle browser url queries,
 * used for filtering etc.
 */
export default () => {
  const router = useRouter()
  const route = useRoute()

  // ?category=1796&q=abc
  // addQuery({ key: 'category', value: 1234 }) -> ?category=1796,1234&q=abc
  const addQuery = ({ key, value, path }) => {
    const newQuery = !route.query[key] ? value : `${route.query[key]},${value}`

    router.push({
      path: path ?? route.path,
      query: {
        ...route.query,
        [key]: newQuery,
      },
    })
  }

  const overrideQuery = (query) => {
    router.push({ query });
  }

  const overrideFullPath = (parameters) => {
    router.push(parameters);
  }

  // ?category=1796&q=abc
  // replaceQuery({ key: 'q', value: 'new-query' }) -> ?category=1796&q=new-query
  const replaceQuery = ({ key, value }) => {
    if (value) {
      router.push({
        query: {
          ...route.query,
          [key]: value,
        },
      })
    } else {
      router.push({ query: { ...route.query, [key]: undefined } })
    }
  }

  // ?category=1796,1234&q=abc
  // removeQuery({ key: 'category', value: 1234 }) -> ?category=1796&q=abc
  const removeQuery = ({ key, value }) => {
    const trimCommas = str =>
      str.replace(/^,+/, '').replace(/,+$/, '').replaceAll(',,', ',')
    const newQuery = trimCommas(route.query[key].replace(value, ''))

    if (newQuery) {
      router.push({
        query: {
          ...route.query,
          [key]: newQuery,
        },
      })
    } else {
      router.push({ query: { ...route.query, [key]: undefined } })
    }
  }

  // ?category=1796&q=abc
  // hasQuery({ key: 'category', value: 1796 }) -> true
  const hasQuery = ({ key, value }) => {
    if (!value) {
      return !!route.query[key]
    }

    return route.query[key]
      ? route.query[key].split(',').includes(value.toString())
      : false
  }

  // ?category=1796,123&q=abc
  // hasQuery({ key: 'category' }) -> [1796, 123]
  const getQuery = ({ key }) => {
    return route.query[key]?.split(',')
  }

  // ?category=1796&genre=1853&q=abc
  // resetQuery('category') -> ?genre=1853&q=abc
  // resetQuery(['category', 'genre']) -> ?q=abc
  const resetQuery = keys => {
    const rawKeys = [].concat(unref(keys))

    const blankQuery = rawKeys.reduce(
      (acc, cur) => ({ ...acc, [cur]: undefined }),
      {}
    )
    const newQuery = { ...route.query, ...blankQuery }

    router.push({ query: newQuery })
  }

  return { addQuery, removeQuery, replaceQuery, hasQuery, getQuery, resetQuery, overrideQuery, overrideFullPath }
}